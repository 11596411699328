<template>
  <div class="container-fluid">
    <Header
      v-if="state.loaded"
      header="Home"
      title="Surat Masuk Terproses"
      :state="state"
      :forbidden="forbid"
    />

    <router-view/>
  </div>
</template>

<script>
import Header from "../../components/pageTitle/index";

export default {
  components: {
    Header
  },
  computed: {
    state() {
      return this.$store.state.documentInSended;
    },
    forbid(){
      if(this.$store.state.profile.permissions.document_in){
        return !this.$store.state.profile.permissions.document_in.create;
      }
      return false;
    }
  },
  methods: {
    // goToAddView() {
    //   this.$store.dispatch("documentInSended/onAdd");
    // }
  }
};
</script>
<style>
.mt-20 {
  margin-top: 14rem !important;
  margin-bottom: 13rem !important;
}
@media only screen and (max-width: 600px) {
  .mt-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
}
</style>
